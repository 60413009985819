@import url(https://fonts.googleapis.com/css2?family=Varela+Round&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "artill_clean_icons";
  src: local("artill_clean_icons"), url(/static/media/artill_clean_icons.414ec5ca.otf) format("truetype");
}
p{
  padding:0;
  margin:0
}

.App{
  color:white;
  height: 100%;
}

.menu{
  transition-duration: 0.2s;
}
*{
  scrollbar-width:thin;
  scrollbar-color: #6969dd #00000000;
  scrollbar-gutter: stable;
  font-family: 'Varela Round', sans-serif;
}

html, body, #root{
  height:100%;
  color:white;
}
.LongTerm{
  transition-duration: .2s;
}
.scollbar {
  border-radius:10px !important
}

::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  border-radius: 100px !important
}

::-webkit-scrollbar-thumb {
  border-radius: 100px !important
}

.row{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.col{
  display: flex;
  flex-direction: column;
  align-items:flex-start;
}

.icon{
  font-family: "artill_clean_icons";
}
