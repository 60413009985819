@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');
p{
  padding:0;
  margin:0
}

.App{
  color:white;
  height: 100%;
}

.menu{
  transition-duration: 0.2s;
}
*{
  scrollbar-width:thin;
  scrollbar-color: #6969dd #00000000;
  scrollbar-gutter: stable;
  font-family: 'Varela Round', sans-serif;
}

html, body, #root{
  height:100%;
  color:white;
}
.LongTerm{
  transition-duration: .2s;
}
.scollbar {
  border-radius:10px !important
}

::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  border-radius: 100px !important
}

::-webkit-scrollbar-thumb {
  border-radius: 100px !important
}

.row{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.col{
  display: flex;
  flex-direction: column;
  align-items:flex-start;
}

.icon{
  font-family: "artill_clean_icons";
}